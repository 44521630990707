import React from "react";
import MonitorSizeTableViewModel from "./MonitorSizeTableDatabase.viewmodel";
import { Select, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";

export default function MonitorSizeTableView() {
    const {
        headers,
        companyList,
        sizeTableResponse,

        handleSearchChange,
        fetchSizeTableByCompany
    } = MonitorSizeTableViewModel();

    return (
        <React.Fragment>
            <div className="flex flex-row justify-evenly">
                <div className="grid grid-cols-2 gap-4">
                    <div className="flex items-center gap-2">
                        <label
                            htmlFor="company-header"
                            className="w-1/4"
                        >
                            Company:{' '}
                        </label>
                        <Select
                            placeholder="กรุณาระบุ Company"
                            onChange={e => {
                                handleSearchChange('company', e.target.value);
                            }}
                            width="sm"
                        >
                            {companyList &&
                                companyList.map((value, index) => (
                                    <option
                                        key={index}
                                        value={value.value}
                                    >
                                        {value.name}
                                    </option>
                                ))}
                        </Select>
                    </div>
                </div>
                <div className="flex flex-col items-end gap-5">
                    <button
                        className="rounded-2xl border-2 px-4 py-2"
                        onClick={fetchSizeTableByCompany}
                    >
                        Search
                    </button>
                </div>
            </div>
            <div className="mt-5 border-t-2">
                <TableContainer
                    whiteSpace="wrap"
                    className="mt-2"
                >
                    <Table
                        size="sm"
                        variant="unstyled"
                    >
                        <Thead>
                            <Tr>
                                {headers.map(header => (
                                    <Th
                                        key={header}
                                        className="border text-center"
                                        style={{
                                            borderColor:
                                                'rgb(212 212 212 / var(--tw-border-opacity)) !important'
                                        }}
                                    >
                                        {header}
                                    </Th>
                                ))}
                            </Tr>
                        </Thead>
                        <Tbody>
                            {sizeTableResponse ? (
                                sizeTableResponse.map((value, index) => (
                                    <Tr
                                        key={index}
                                        className="[&>td]:border [&>td]:border-neutral-300"
                                    >
                                        <Td>{value.tableName}</Td>
                                        <Td>{value.schemaName}</Td>
                                        <Td>{value.rows}</Td>
                                        <Td>{value.totalSpaceKB}</Td>
                                        <Td>{value.totalSpaceMB}</Td>
                                        <Td>{value.usedSpaceKB}</Td>
                                        <Td>{value.usedSpaceMB}</Td>
                                        <Td>{value.unusedSpaceKB}</Td>
                                        <Td>{value.unusedSpaceMB}</Td>
                                    </Tr>
                                ))
                            ) : (
                                <tr className="text-center">
                                    <td colSpan={headers.length}>
                                        <span>No data..</span>
                                    </td>
                                </tr>
                            )}
                        </Tbody>
                    </Table>
                </TableContainer>
            </div>
        </React.Fragment >
    )
}