import { AxiosError } from "axios";
import { validateDataResponse } from "commons/helpers";
import { DBSizeTableChecker } from "./databaseChecker.schema";
import { CallGetSizeTableDatabase } from "./databaseChecker.datasource";

export async function GetSizeTableDatabase(params: { company: string }) {
    try {
        const response = await CallGetSizeTableDatabase(params);
        const result: DBSizeTableChecker[] = validateDataResponse(response);

        return result;
    } catch (error) {
        if (error instanceof AxiosError && error.response) {
            throw new Error(validateDataResponse(error.response).message);
        }

        throw error;
    }
}