import { DBSizeTableChecker, GetSizeTableDatabase } from "data/DatabaseChecker";
import useLoader from "hooks/useLoader";
import { useState } from "react";
import Swal from "sweetalert2";

export default function MonitorSizeTableViewModel() {
    const loading = useLoader();

    const headers: string[] = [
        'Table Name',
        'Schema Name',
        'Rows',
        'Total Space KB',
        'Total Space MB',
        'Used Space KB',
        'Used Space MB',
        'Unused Space KB',
        'Unused Space MB',
    ]
    const companyList: { value: string; name: string }[] = [
        { name: 'Eps Prd', value: 'eps_db_prd' },
        { name: 'Qmix Prd', value: 'qmix_db_prd' },
        { name: 'Scg Cement Prd', value: 'scg_cement_db_prd' },
        { name: 'Scieco Prd', value: 'scieco_db_prd' },
        { name: 'Slsn Prd', value: 'slsn_prd' },
        { name: 'Slts Prd', value: 'slts_prd' },
        { name: 'Sric Prd', value: 'sric_db_prd' }
    ];

    const [searchHelp, setSearchHelp] = useState<{ company: string }>({
        company: '',
    });

    const [sizeTableResponse, setSizeTableResponse] = useState<DBSizeTableChecker[]>();

    const handleSearchChange = <K extends keyof { company: string }>(
        key: K,
        value: { company: string }[K]
    ) => {
        setSearchHelp(prevState => ({
            ...prevState,
            [key]: value
        }));
    };

    const fetchSizeTableByCompany = async () => {
        try {
            loading.show();

            const response = await GetSizeTableDatabase(searchHelp);

            if (response) {
                console.log(response)
                setSizeTableResponse(response);
            }
        } catch {
            Swal.fire({
                icon: 'error',
                title: 'ขออภัยไม่สามารถ ค้นหาข้อมูลได้'
            });
        } finally {
            loading.hide();
        }
    }

    return {
        headers,
        companyList,
        sizeTableResponse,

        handleSearchChange,
        fetchSizeTableByCompany
    };
}